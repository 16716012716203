@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;800&display=swap');

#homepage{
  /* height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 5vw; */
  overflow: hidden;
  background-color: #181818;

  }

  #Homeheading{
    height: 78vh;
    width: 95%;
    gap: 9vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    border-bottom: 1px solid #2c2c2c;
    padding-bottom: 2%;
    margin-top: 0%;
    position: relative;
  }
  #homeArrow{
    position: absolute;
    bottom: 2%;
    right: 0;
    /* background-color: white; */
    filter: invert(1);
    border-radius: 50%;
    padding: 1% 1%;
    width: 3%;
    transform: rotate(175deg);
    border: 1px solid #d2d2d2;
  }

  #Homeheading > span:nth-child(1){
      font-size: 5rem;
      font-family: 'Matterregular';
      font-weight: 700;
      line-height: 100px;
      color: rgb(255, 255, 255);
      letter-spacing: -1px;

  }

  #DisplayImage1{
    width: 95%;
    height: 95vh;
    border-radius: 15px;
    overflow: hidden;
    margin: 5% 0%;

  }
  #DisplayImage1 > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  

  #title2{
    font-family: 'Montserrat',sans-serif;
    font-size: .9rem;
    font-weight: 400;
    letter-spacing: .025rem;
    line-height: 1.875;
    display: grid;
    color: black;
    padding-right: 60vw;
    padding-left: 3vw;
    padding-top: 7vh;
    place-items: center;
    background-color: white;
  }

  #videopage{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 0vh;
    margin-bottom: 10vh;
    margin-left: 0vw;
    margin-right: 0vw;
    padding: 20vh 2vw;
    gap: 2vw;
    background-color: white;
  
  }
  
  #videopage > video:nth-child(1){
    width: 62vw;
    border-radius: 12px;
  }
  
  #videodescription{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #000;
    font-size: 2.5rem;
    border-radius: 13px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    /* background-color: steelblue; */
  
  }
  
  
  #videodescription > span:nth-child(1){
    margin-bottom: 2.5vh;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 2.5vh;
    font-weight: 600;
  }
  #videodescription > span:nth-child(2){
    font-family: 'Montserrat',sans-serif;
    font-size: .9rem;
    font-weight: 400;
    letter-spacing: .025rem;
    line-height: 1.875;
    text-align: start;
  }
  
@media only screen and (max-width: 450px){
  #Homeheading > span:nth-child(1) {
    font-size: 3rem;
    line-height: unset;
    margin-bottom: 20%;
    letter-spacing: 0px;

}
#Homeheading {
  height: unset;
  margin-top: 50%;
}
#homeArrow {
  padding: 2% 2%;
  width: 7%; 
     bottom: 5%;
  right: 4%;

}
#DisplayImage1 {
  height: 39vh;
}
}