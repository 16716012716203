#servicescontainer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0% 4%;
  background-color: #181818;

}

#servicesHeading{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 60%;

}
#servicesHeading > span:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    z-index: 11;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
    border: 1px solid #2d2d2d;
    padding: 3px 15px;
    border-radius: 50px;
}
#servicesHeading > span:nth-child(2){
    font-size: 32px;
    font-family: 'Matterregular';
    color: #2f2f2f;
  
}

#Offerings{
width: 100%;
margin-top: 5%;
}

#Offerings > div{
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;
    transition: 0.2s;
    margin: 6vh 0;
    color: white;
    border-bottom: 1px solid #2e2e2e;
    padding-bottom: 1%;
}


 
#servicescontainer > div > div > img{
    width: 5%;
    border-left: 1px solid #2a2a2a;
    padding-left: 2%;
}
#servicescontainer > div > div > span{
  font-family: 'nova';
font-size: 25px;
}

@media only screen and (max-width: 415px) {
    #servicescontainer {
        margin-top: 6%;
    }
    #servicesHeading > span:nth-child(1) {
        font-size: 17px;
    }
    #servicesHeading {
        width: 100%;
    }
    #servicescontainer > div > div > span {
        font-size: 16px;
    }
    #servicescontainer > div > div > img {
        width: 10%;
    }
}
