#clientcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #ffffff; */
    padding: 0% 4%;

}
#clientheading{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;


}
#clientheading > span:nth-child(1){
    font-size: 1.6rem;
    font-family: "nova";
    color: rgb(255, 255, 255);
    margin-bottom: 1vh;
  }
  #clientheading > span:nth-child(2){
    font-size: 2.7rem;
  /* font-family: 'newyorkregular'; */
  font-family: 'Raleway', sans-serif;
font-weight: 800;
    color: rgb(255, 255, 255);
  }


  #clientflexbox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 71vw;
  }

  #clientflexbox > div{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all .1s ease;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
    height: 58vh;
    width: 21vw;
    margin: 15px 15px;
    /* border: 1px solid rgb(186 186 186); */
    border-radius: 1em;
  
  }
  #clientflexbox > div:nth-child(4){
    background-image: url('../images/clients/fashiontech.jpeg');
  }
  #clientflexbox > div:nth-child(2){
    background-image: url('../images/clients/orionis.jpeg');
  }
  #clientflexbox > div:nth-child(3){
    background-image: url('../images/clients/askinsight.jpeg');
  }
  #clientflexbox > div:nth-child(1){
    background-image: url('../images/clients/jklaxmi.png');
  }
  #clientflexbox > div:nth-child(5){
    background-image: url('../images/clients/sk.png');
  }
  #clientflexbox > div:nth-child(6){
    background-image: url('../images/clients/sav.png');
  }
  #clientflexbox > div:nth-child(7){
    background-image: url('../images/clients/nipman.jpeg');
  }
  #clientflexbox > div:nth-child(8){
    background-image: url('../images/clients/ganesha.png');
  }
  #clientflexbox > div:nth-child(9){
    background-image: url('../images/clients/deluxe.jpeg');
  }
  .downwards{
    transform: translateY(10vh);
  }


@media only screen and (max-width: 415px) {
    #clientcontainer {
        margin-top: 6vh;
        gap: 4vh;
        margin-bottom: 15%;
    }
    
    #clientflexbox {
        flex-direction: column;
        flex-wrap: nowrap;
    }
    #clientflexbox > div {
      height: 22vh;
      width: 80vw;
      margin: 4px 15px;
  }
    
     
      .downwards {
        transform: translateY(0vh);
    }
    
    #clientheading > span:nth-child(2) {
        font-size: 2.2rem;
    }
}
