#testimonialcontainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    width: 100%;
    /* margin-top: 8vh; */
    padding: 7vh 0vw;
  background-color: #181818;

}


#theading{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: rgb(52, 52, 52);
    margin-bottom: 5%;
    padding-right: 47%;
    margin-top: 4%;
  }

  #theading > span:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4%;
    z-index: 11;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
    border: 1px solid #2d2d2d;
    padding: 3px 15px;
    border-radius: 50px;
  }

  #theading > span:nth-child(2){
  width: 100%;
  font-weight: 700;
  font-size: 50px;
  font-family: 'Matterregular';
  color: rgb(255, 255, 255);

  }

  #tcard{
    color: black;
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: center;

  }
  /* #tcard > div:nth-child(1){
    margin-left: 6vw;
    margin-right: 4vw;
  }
  #tcard > div:nth-child(3){
    margin: 9vh 6vw;
  } */
  .cardchildt{
    border-radius: 7px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    align-items: flex-start;
    background: #292929;
    flex-direction: column;
    width: 27%;
    padding: 4vh 2vw;
    margin: 0vh 1vw;
  }
  .cardchildt > img{
    width: 16%;
    transform: rotate(180deg);

  }
  .cardchildt > span{
    color: #ffffff;
    letter-spacing: 0.025rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    line-height: 1.875;
    font-weight: 400;

    margin: 3vh 0vw;

  }
  .name{
    display: flex;
    flex-direction: column;
  }
  .name > span:nth-child(1){
    font-family: 'Dancing Script', cursive;
    font-size: 1.4rem;
    color: #569bd5;
  }
  .name > span:nth-child(2){
    font-family: 'nova';
font-size: 0.9rem;
color: grey;
  }

  

  
  @media only screen and (max-width: 415px) {
    
    #tcard {
      flex-direction: column;
  }

    #theading > span:nth-child(1) {
      font-size: 17px;
  }
  #theading {
    margin-bottom: 5%;
    padding-right: 9%;
    margin-top: 4%;
}
#theading > span:nth-child(2) {
  font-size: 41px;
}
#testimonialcontainer {
  width: 94%;
  padding: 7vh 3%;
}
.cardchildt {
  width: 95%;
  padding: 4vh 3%;
  margin: 1vh 0;
}


}


