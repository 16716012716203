
#whatwedocontainer {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10%;
  background-color: #181818;

  }
  
  #weheading {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 40%;
    gap: 4%;

  }
  #weheading > span:nth-child(1) {
    color: #fff;
    font-size: 70px;
    font-family: 'Matterregular';
    font-weight: 600;
  }
  
  #weheading > span:nth-child(2) {
    color: #919191;
    font-family: 'Matterregular';
    font-size: 17px;

  }
  
  #works {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    /* width: 50%; */
  }
  
  #works > div {
    position: sticky;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 39vw;
    height: 44vh;
    padding: 0% 5%;
    margin: 5% 0%;
    background-color: #181818;

    gap: 2vw;
  }

  #works > div:nth-child(1){
    top: 28%;
  } 
  #works > div:nth-child(2){
    
    top: 30%;
  } 
  #works > div:nth-child(3){
    
    top: 32%;
  } 
  #works > div:nth-child(4){
    
    top: 34%;
  } 
  #works > div:nth-child(5){
    top: 36%;

  } 


  #works > div:hover {
    background-size: 430px;
  }
  
  #works > div > span:nth-child(1) {
    position: absolute;
    right: 0;
    margin: 4% 4%;
    top: 0;
    border-radius: 10px;
    font-family: 'newyorkregular';
    color: rgb(121 121 121);
    font-size: 50px;
    display: grid;
    place-items: center;
  }
  #works > div > span:nth-child(2) {
    border-radius: 10px;
     font-family: "nova";
     color: rgb(255, 255, 255);
     font-size: 25px;
     display: grid;
     place-items: center;
     padding-right: 40%;

    }
    #works > div > span:nth-child(3) {
      font-family: 'nova';
    font-size: 17px;
    color: grey;
    text-align: start;
    padding-right: 20%;
  }

  @media only screen and (max-width: 415px) {
    
    #whatwedocontainer {
      margin-top: 15vh;
      gap: 2vh;
      flex-direction: column;
  }
  #weheading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: 64vh;
    width: 95%;
    padding: 32% 2%;
    gap: 7%;

}

#weheading > span:nth-child(2) {
  font-size: 14px;
  text-align: center;
}
#weheading > span:nth-child(1) {
  font-size: 60px;
  margin-bottom: 4%;
}

#works > div {
  overflow: hidden;
  width: 85%;
  height: 40vh;
  padding: 0% 5%;
  gap: 7%;
}
#works > div:nth-child(1) {
  top: 45%;
}
#works > div:nth-child(2) {
  top: 46%;
}
#works > div:nth-child(3) {
  top: 47%;
}
#works > div:nth-child(4) {
  top: 48%;
}
    
#works > div:nth-child(5) {
  top: 49%;
}

#works > div > span:nth-child(2) {
  font-size: 23px;
}
    


  }

