#aboutwrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 94%;
  overflow: hidden;
  position: relative;
  padding: 0% 3%;
  padding-top: 8%;
  padding-bottom: -3%;
  background-color: #181818;

  }
  
  #aboutheading{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
    border: 1px solid #2d2d2d;
    padding: 3px 15px;
    border-radius: 50px;
    margin-bottom: 2%;
  }
  
  #CompanyInfo{
    height: 110vh;
    width: 94%;
    padding: 0% 3%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: grey; */
    border-bottom: 1px solid #2c2c2c;
  background-color: #181818;

  }
  #CompanyInfo > span:nth-child(1){
    font-size: 50px;
    font-family: 'Matterregular';
    color: #fff;
  }
  #introWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #introWrapper > img:nth-child(1){
    width: 2%;
    background-color: #fff;
    margin-top: -22%;
    margin-right: 3%;
    padding: 1% 1%;
    border-radius: 50%;
    transform-origin: center;
    transform: rotate(85deg);
  }
  #introWrapper > span:nth-child(2){
    font-size: 15px;
    font-family: 'nova';
    color: #919191;
    width: 45%;
    margin-right: 2%;
  }
  #introWrapper > span:nth-child(3){
    font-size: 15px;
    font-family: 'nova';
    color: #919191;
    width: 40%;
  }
  

  
  #founderwrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 15vh 14vw;
  background-color: #181818;

  }
  #founderwrapper > span:nth-child(2){
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: 2vw;
    color: grey;
  }
  #founderwrapper > span:nth-child(3){
    font-family: 'nova';
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
  }
  #founderwrapper > span:nth-child(4){
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    color: grey;
    font-size: 1rem;
    margin-top: 2px;
    margin-left: 1px;
  
  }
  #founderwrapper > span:nth-child(5){
    padding-right: 12vw;
    font-size: 15px;
    font-family: 'nova';
    color: #bcbcbc;
    margin: 3% 0%;

  
  }
  
  #founder{
    width: 25vw;
    border-radius: 12px;
    filter: grayscale(1);
  }
  #badges{
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  
  }
  #badges > img:nth-child(1){
    width: 8vw;
  }
  #badges > img:nth-child(2){
    width: 11vw;
  }
  

  #awardswrapper{
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 20vh 17vw;
    gap: 10vh;
    border-radius: 12px;
    /* max-width: 0vh 1vw; */
  }
  #awardsheading{
    font-family: 'Raleway',sans-serif;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: .025rem;
    color: steelblue;

  }
  #awards{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #awards > div:nth-child(1){
    color: grey;
  }
  #awards > div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 10vh;
    width: 65vw;
    border-bottom: 1px solid #ebebeb;
    font-family: 'Raleway',sans-serif;
    font-size: .8rem;
    font-weight: 500;
    letter-spacing: .025rem;
    color: black;
    gap: 42vw;
    padding-right: 2vw;
    padding-left: 2vw;
    transition: all .1s ease;
  }
  #awards > div:nth-child(1){
    color: steelblue;
    background-color: white;
  }
  
  #awards > div:nth-child(1):hover{
    color: steelblue;
    background-color: white;
  }
  #awards > div:hover{
    background-color: #e6f4ff;
  }

  @media only screen and (max-width: 440px){
    #aboutwrapper {
      padding-top: 15%;
  }

#aboutheading {
  margin-bottom: 6%;
  font-size: 16px;
}
#CompanyInfo{
  height: unset;
}
#CompanyInfo > span:nth-child(1) {
  font-size: 30px;
}
#introWrapper > span:nth-child(2){
  width: 100%;
  font-size: 13px;
  margin-bottom: 7%;
}
#introWrapper > span:nth-child(3){
  width: 100%;
  font-size: 13px;
  margin-bottom: 7%;
}
#introWrapper{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
#introWrapper > img:nth-child(1) {
  width: 7%;
  margin-top: 10%;
  margin-right: 3%;
  margin-bottom: 10%;
}
#founderwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 13% 4%;
  background-color: #181818;
}

#founder {
  width: 100%;
}
#founderwrapper > span:nth-child(2) {
  font-size: 10px;
  margin-top: 4%;
}
#founderwrapper > span:nth-child(3) {
  width: 100%;
  /* font-size: 13px; */
  /* margin-top: 4%; */
}
#founderwrapper > span:nth-child(4) {
  font-size: 10px;
  /* margin-top: 4%; */
}
#founderwrapper > span:nth-child(5) {
  padding-right: 0vw;
  font-size: 10px;
  }
  #badges {
    justify-content: flex-start;
}
  #badges > img:nth-child(1) {
    width: 18%;
}
#badges > img:nth-child(2) {
  width: 23%;
}

}
  