
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500&display=swap');



#contactpage{
    user-select: none;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin: 0 4%;
    transform-origin: top center;
    animation: contact .5s forwards ease;
    scale: 1;
    margin-top: 10%;
  background-color: #181818;
  overflow: hidden;

}

#contactheading{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  width: 100%;
  margin-bottom: 8%;
  }
  
  #contactheading > span:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    z-index: 11;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
    border: 1px solid #2d2d2d;
    padding: 3px 15px;
    border-radius: 50px;
  }
  #contactheading > span:nth-child(2){
    width: 80%;
    font-size: 50px;
    font-family: 'Matterregular';
    color: #2f2f2f;

  
  }


#Email-Form-Container{
    height: 60vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 6%;

}

#contactform{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    height: 100%;
    border-right: 1px solid #2e2e2e;

}

#contactform > input{
    font-family: 'Raleway', sans-serif;
    border: none;
    width: 84%;
    padding-top: 3%;
    font-size: 1.5rem;
    padding-left: 4%;
    padding-bottom: 3%;
    border-radius: 12px;
    margin: 2vh 0vw;
    word-wrap: break-word;
    border: 1px solid #2e2e2e;
    outline: transparent;
    transition: all .2s ease;
    background-color: transparent;
    color: white;

}

#contactform > input::placeholder{
    color: rgb(63, 63, 63);
}



#submitbtn{
    transition: all .2s ease;
    margin-top: 5%;
    background-color: transparent;
    border: none;
    display: flex;
}
#send{
    width: 5%;
    margin-left: 2%;
    transition: all .2s ease;

}
#submitbtn:hover #send{
transform: translate(10px, -10px);
}

#circulartext{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10vh;
    z-index: 10;
    height: 16vh;
    color: black;
    width: 9vw;
    right: 10vh;
    animation: rotation 13s forwards linear infinite;

}

@keyframes rotation {
    100%{
        transform: rotate(360deg);
    }
}
#ctxt{
    fill: rgb(255, 255, 255);
    font-size: 25px;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 6px;

}


#emailcontainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 40%;
    height: 100%;
  }


  #emailcontainer > a{
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    color: #7d7d7d;
    /* border: 1px solid #343434; */
    border-radius: 62px;
    font-size: 15px;
    transition: all .2s ease;
    margin: 1vh 0vw;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .dot{
    height: 6px;
    width: 6px;
    background-color: white;
    border-radius: 50%;
    margin-right: 4%;
  }

  #emailcontainer > a:nth-child(3){
    border: none;
  }
  #emailcontainer > a:hover{
 text-decoration: underline;
  }

  @media only screen and (max-width: 415px) {
   
    #contactheading > span:nth-child(1) {
      font-size: 17px;
  }
  #contactheading > span:nth-child(2) {
    width: 100%;
    font-size: 32px;
    font-family: 'Matterregular';
    color: #2f2f2f;
}
#circulartext {
  top: 0vh;
  width: 15%;
  right: 0%;
}
#Email-Form-Container {
  flex-direction: column;
  height: unset;
}
#emailcontainer {
  width: 100%;
  margin: 8% 0%;

}
#contactform {
  width: 98%;
}
#send {
  width: 7%;
}
  }
  
  
