
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap');   
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;800&display=swap');



/* 
.fonts{
    font-family: 'Dancing Script', cursive;
     font-family: 'Work Sans', sans-serif;
        font-family: 'Quicksand', sans-serif;
        font-family: 'Figtree', sans-serif;
        font-family: 'Cabin', sans-serif;
        font-family: 'Josefin Sans', sans-serif;
        font-family: 'Raleway', sans-serif;
        font-family: 'Montserrat', sans-serif;
} */


@font-face {
  font-family: 'Matterregular';
  src: url('./Fonts/Matter/Matter-Regular.woff2') format('woff2'),
      url('./Fonts/Matter/Matter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'nova';
  src: url('./Fonts/HK NOVA/hknova-medium-webfont.woff') format('woff2'),
       url('./Fonts/HK NOVA/hknova-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'newyorkregular';
  src: url('./Fonts/newyork/newyork_personal_use-webfont.woff2') format('woff2'),
       url('./Fonts/newyork/newyork_personal_use-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

*::selection{
  color: #1784a2;
}


#logo{
  position: fixed;
  background-image: url('./images/logo.jpeg');

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid steelblue;
  border-radius: 50%;
  height: 60px;
  left: 3vw;
  /* mix-blend-mode: luminosity; */
  top: 5vh;
  width: 60px;
  z-index: 400;
  /* transform: translateY(12px); */
}

#sticky {
  position: sticky;
  position: -webkit-sticky;
  background: #f83d23;
  width: 100px;
  height: 100px;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 6px #000;
  color: #fff;
}


  #view{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: skyblue;
    transition: opacity 300ms linear, transform 300ms linear, background-color 300ms ease, height 300ms ease, width 300ms ease;
border-radius: 50%;
    font-size: 1rem;
    letter-spacing: 0.3px;
    color: #000;
  font-family: 'hk_novamedium';

    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }
  .initialclass{
    width: 12px;
    height: 12px;
mix-blend-mode: difference;
}

#paralax{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  object-fit: contain;
  background-color: #fff;
}



  @media only screen and (max-width: 415px) {
    #view{
      background-color: transparent;
    }
    #logo{
      height: 50px;
      left: 0vw;
      top: 0vh;
      margin: 5% 4%;
      width: 50px;
    }
   
  }
  
  
