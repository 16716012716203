#footercontainer{
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    height: 70vh;
    margin: 0 2%;
    margin-top: 10%;
    position: relative;
    border: 1px solid #2e2e2e;
    border-radius: 26px;
}
#FooterHeading{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 50%;
    border-right:1px solid #2e2e2e;
}
#FooterHeading > span{
    font-size: 53px;
    font-family: 'nova';
    color: #fff;
    letter-spacing: -2px;
    margin: 4% 6%;
    line-height: 61px;
}

#pages{
    height: 92%;
    width: 25%;
    padding-top: 3%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #2e2e2e;

}
#pages > span:nth-child(1){
    font-family: 'Matterregular';
    font-size: 24px;
    color: white;
    margin-bottom: 6%;
    padding: 5px 24%;



}
.Links{
    font-family: 'Work Sans', sans-serif;
    color: #acacac;
    font-size: 16px;
    padding: 7px 4%;
    transition: 0.3s;
    margin: 1.2% 25%;
    border-radius: 6px;
    border: 1px solid #282828;
    transition: all .2 ease;
}
.Links:hover{
    scale: 1.05;
border: 1px solid #28282800;

}


#socials{
    height: 92%;
    width: 25%;
    padding-top: 3%;
    display: flex;
    flex-direction: column;
    /* border-right: 1px solid #2e2e2e; */
}
#socials > span{
    font-family: 'Matterregular';
    font-size: 24px;
    color: white;
    margin-bottom: 6%;
    padding: 5px 24%;
}
#socials > a{
    font-family: 'Work Sans', sans-serif;
    color: #acacac;
    font-size: 16px;
    padding: 7px 4%;
    transition: 0.3s;
    margin: 1.2% 25%;
    border-radius: 6px;
    border: 1px solid #282828;
    transition: all .2 ease;
    text-decoration: none;
}

#copyRight{
    /* position: relative; */
    color: #acacac;
    margin: 1% 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Matterregular';
    padding: 1.2% 3%;
    border: 1px solid #2e2e2e;
    border-radius: 20px;

}

@media only screen and (max-width: 415px) {

#footercontainer {
    flex-direction: column;
    height: unset;
    align-items: flex-start;
}
#FooterHeading {
    width: 100%;
}
#FooterHeading > span {
    font-size: 45px;
    letter-spacing: -2px;
    line-height: 53px;
}
#pages {
    width: 100%;
    padding-top: 10%;
}
#socials {
    width: 100%;
    padding-top: 10%;
    margin-bottom: 3%;
}
#pages > span:nth-child(1) {
    font-size: 17px;
    padding: 0px 6%;
    margin-bottom: 5%;
}
.Links {
    font-size: 14px;
    padding: 7px 4%;
    margin: 1.2% 6%;
    width: 50%;
}
#socials > span {
    font-size: 17px;
    margin-bottom: 5%;
    padding: 2px 6%;
}
#socials > a {
    font-size: 14px;
    padding: 7px 4%;
    margin: 1.2% 6%;
    width: 50%;
}
#copyRight {
    margin: 3% 2%;
    padding: 5% 3%;
    font-size: 10px;
}

}
