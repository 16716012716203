#LoadingScreen{
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgb(0, 0, 0);
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1000;
    opacity: 0;
  }