#ImagesContainer{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#fullView{
  height: 100vh;
  width: 100%;
  background-color: #2c2c2c;
  z-index: 10;
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
#fullView > img{
  height: 93vh;
  border-radius: 15px;
}
#fullView > button{
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  font-family: 'Matterregular';
  font-size: 20px;
  border: 1px solid grey;
  background: transparent;
  color: #fff;
  padding: 6px 15px 9px 15px;
  border-radius: 4px;
}

#ImagesHeading{
  color: white;
  width: 90%;
  padding: 2% 0%;

}

#Images-img-1{
  height: 98vh;
  width: 97%;
  border-radius: 15px;
  margin: 5% 0%;
  overflow: hidden;
}

#Images-img-1 > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#ProjectDescription{
  width: 92%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: grey;
  font-family: 'Matterregular';
  font-size: 15px;
  margin: 0% 4%;
  margin-top: 20%;

  border-bottom: 1px solid #2c2c2c;
  padding: 0% 1%;
  padding-bottom: 2%;

}
#ProjectDescription > span:nth-child(1){
  font-family: 'Matterregular';
  font-size: 60px;
  letter-spacing: -2px;
  padding: 0 0;
  margin-bottom: -8px;

  color: #fff;
  border:none;
}
#ProjectDescription > span{
  border: 1px solid #2c2c2c;
  padding: 4px 17px;
  border-radius: 30px;
}

#scrollImages{
  height: 1000vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 2%;
}
#stickyImages{
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#TranslateImages{
  height: 95%;
  width: 97%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

#TranslateImages > img{
  border-radius: 15px;
  height: 100%;
  object-fit: cover;
  margin: 0% 2%;
}

#HeroImages{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.heroimages{
  width: 40%;
  object-fit: cover;
  margin: 3% 2%;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}

.heroimages > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  position: relative;
  border-radius: 15px;

}

.heroimages > div{
  position: absolute;
  border-radius: 15px;
opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: grid;
  top: 0;
  left: 0;
  background-color: #0000006b;
  place-items: center;
  font-family: 'nova';
  color: #fff;
  font-size: 22px;
  transition: all .2s ease;
}

.heroimages:hover > div{
  opacity: 1;
}

@media only screen and (max-width: 430px){
  #ProjectDescription {
  align-items: flex-start;

    flex-direction: column;
    font-size: 12px;
    margin-top: 50%;
}
#ProjectDescription > span:nth-child(1) {
  font-size: 50px;
  margin: 5% 0%;
}
.heroimages {
  width: 98%;
  object-fit: cover;
  margin: 3% 2%;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

#Images-img-1 {
  height: 67vh;
  width: 98%;
}
}