#loadingscreen{
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9vw;
    position: fixed;
    z-index: 4000;
    animation: disappear 0.5s 5s ease forwards, disappear2 0s 5.7s forwards ease ;
}

@keyframes disappear {
    100%{
        opacity: 0;
    }
}
@keyframes disappear2 {
    100%{
        display: none;
    }
}


#loadingscreenchild{
    position: absolute;
    width: 26%;
    animation: shuffle 1s 1s ease forwards;
    scale: 0.5;
    opacity: 0;

}
@keyframes shuffle {
    100%{
        scale: 1;
    opacity: 1;
    }
}


#loadingsvg{
    position:absolute;
    width:290px;
    height:290px;
    z-index:1000;
    transform: rotate(-90deg);
}
#loadingsvg > circle
{
  width:100%;
  height:100%;
  fill:none;
  stroke:#1087c9;
  stroke-width:3px;
  stroke-linecap:round;
  transform:translate(5px, 5px);
}
#loadingsvg > circle:nth-child(1){
    stroke-dasharray: 1617;
    stroke-dashoffset: 1617;
    animation: circlea 2s 1.6s forwards ease;
  }
  @keyframes circlea {
    100%{
    stroke-dashoffset: 736;

    }
  }

@media only screen and (max-width: 415px) {

    #loadingscreenchild {
        width: 50vw;
    }
    #loadingsvg > circle
{
  stroke-width:2px;
}
#loadingsvg > circle:nth-child(1){
animation-duration: 7s;
    /* animation: circlea 2s 1.6s forwards ease; */
  }
    
}