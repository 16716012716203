@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


#menuBg{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgb(0 0 0 / 68%);
  z-index: 56;
  transition: all .3s ease;
}

#navbar {
  user-select: none;
    display: flex;
    align-items: center;
    transform-origin: top right;
    justify-content: center;
    z-index: 60;
    position: fixed;
    margin: 5vh 0vw;
    top: 0vh;
    right: 2vw;
    width: 0%;
    font-size: 1rem;
    display: flex;
    align-items: flex-end;
    font-family: 'Montserrat', sans-serif;

    justify-content: center;
    flex-direction: column;
    gap: 4vh;
  
  border-radius: 10px;

    padding-left: 3vw;

    
}
  
#menu{
  color: #fff;
  font-family: 'Matterregular';
  height: 30px;
  border: 1px solid #3c3c3c;
  font-weight: 700;
  width: 75px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap ;
  overflow: hidden;

}
#menu > span{
  transition: all .3s ease;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  /* background-color: grey; */

}

#menucontainer {
  display: flex;
  transition: 0.4s;
  background-color: #222222;

  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 10px;
  padding-top: 6vh;
  transform-origin: top right;
  padding-right: 7vh;
  padding-bottom: 5vh;
  padding-left: 3vw;
  border-radius: 12px;

}

  .menuLinks{
    transition: all .2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 2.5% 7%;
    width: 78%;
    color: rgb(255, 255, 255);
    font-size: 33px;
  }
  .menuLinks > img{
    filter: invert(1);
    width: 8%;
    opacity: 0;
    transform: translateX(-10px);
    transition: all .2s ease;
  }
  .menuLinks:hover{
    background: #2a2a2a;

  }
  .menuLinks:hover > img{
    opacity: 1;
    transform: translateX(0px);
  }

  #get{
    display: flex;
     flex-direction: column;
     margin-left: 26vw;
     margin-top: 6%;
  
  }
  #get > span:nth-child(1){
    color: rgb(255, 255, 255);
    padding-bottom: 1vh;
    margin-bottom: 1vh;
    border-bottom: 1px solid #4a4a4a;
    white-space: nowrap;
    width: 13%;
  }
  #get > a:nth-child(2){
    color: grey;
    font-size: .9rem;
  }
  #get > a:nth-child(2):hover{
    text-decoration: underline;
  }

  
  @media only screen and (max-width: 415px) {
    .menuLinks{
    background: #2a2a2a;
      font-size: 22px;
    }
    .menuLinks > img{
      filter: invert(1);
      width: 8%;
      transition: all .2s ease;
      opacity: 1;
      transform: translateX(0px);
    }
   
   
   
    #navbar {
      margin: 3vh 0vw;
  }
  #menucontainer {
    width: 90vw;
    padding: 5vh 3vw;
}

#menucontainer > span {
  font-size: 2rem;
}
#menu > span {
  font-size: 14px;
}
#get {
  margin-left: 43%;
  margin-top: 10%;
}
#get > span:nth-child(1) {
  font-size: 12px;
}
#get > a:nth-child(2) {
  font-size: 12px;
}



  }

  
