#projectcontainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
    transition: 0.8s;
    padding-top: 10%;
  background-color: #181818;


}

#projectheading{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
width: 100%;
margin-bottom: 3%;
}

#projectheading > span:nth-child(1){
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1%;
  z-index: 11;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #acacac;
  border: 1px solid #2d2d2d;
  padding: 3px 15px;
  border-radius: 50px;
}
#projectheading > span:nth-child(2){
  text-align: center;
  width: 60%;
  font-size: 50px;
  font-family: 'Matterregular';
  color: rgb(255, 255, 255);

}
#Projects{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#Projects > div:nth-child(1){
  border-right: 0px;
}
#Projects > div:nth-child(2){
  border-right: 0px;
}
#Projects > div:nth-child(3){
  border-top: 0px;
  border-right: 0px;
}
#Projects > div:nth-child(4){
  border-top: 0px;
}
#Projects > div:nth-child(5){
  border-top: 0px;
}


.ProjectCards{
  height: 70vh;
  width: 43%;
  padding: 1% 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #232323;
  gap: 3%;
}
.ProjectCards  > img{
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 15px;

}
.ProjectCards  > div:nth-child(2){
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #adadad;
  font-family: 'Matterregular';
}
.ProjectCards  > div:nth-child(3){
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-family: 'nova';
  font-size: 28px;
  letter-spacing: -1px;
}
.visitbtn{
  font-family: 'Matterregular';
  font-size: 17px;
  text-decoration: none;
  padding: 3px 5%;
  margin-right: 3%;
  border-radius: 20px;
  background-color: transparent;
  color: white;
  border: 1px solid #313131;
  transition: all .2s ease;
}

.visitbtn:hover{
  scale: 1.1;
  border: 1px solid transparent;
}

#reelContainer{
  margin: 12% 0%;
}
#reelHeading{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0% 6%;
  margin-bottom: 2%;
}
#reelHeading  > span:nth-child(1){
  font-family: 'nova';
  font-size: 47px;
  color: #fff;
  letter-spacing: -1px;
  margin-top: -2%;

}
#reelHeading  > span:nth-child(2){
  font-family: 'Matterregular';
  font-size: 15px;
  color: grey;
  width: 40%;

}

#Reel{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin: 10% 0%; */
}

#Reel  > video{
  object-fit: cover;
  position: absolute;
  height: 92%;
  width: 96%;
  border-radius: 15px;
  z-index: 0;
  /* filter: brightness(0.3); */
  /* border: 1px solid grey; */
}
.playReel{
  z-index: 1;
  font-family: 'newyorkregular';
  font-size: 28px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 1px solid grey;
  color: white;
  background-color: transparent;
  transition: all .2s ease;
  position: absolute;
}

#PlayReel:hover{
  scale: 1.1;
  border: 1px solid transparent;
}
#PlayReel:active{
  scale: .9;
}



@media only screen and (max-width: 415px) {
  #projectheading > span:nth-child(1) {
    margin-bottom: 2%;
}
#projectcontainer {
  padding-top: 20%;
}
#projectheading > span:nth-child(2) {
  width: 90%;
  font-size: 30px;
}
.ProjectCards {
  width: 94%;
  padding: 1% 3%;
}

#reelHeading {
  flex-direction: column;
}
#reelHeading > span:nth-child(1) {
  font-size: 30px;
  letter-spacing: -1px;
  margin-top: -2%;
  margin-bottom: 5%;
}
#reelHeading > span:nth-child(2) {
  font-size: 14px;
  width: 100%;
}
}




 